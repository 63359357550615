import { css } from 'lit';
import { MILLISECONDS_PER_SECOND } from '../constants';

export const rampMeterColumns = <const>[
	'rampName',
	'rampStatus',
	'rampRoute',
	'rampMileMarker',
	'rampDirection',
	'meteringPlan',
];

export const ConfigRampMeter = {
	Map: {
		//  Leaflet map will center on this point, at the provided zoom level
		//  https://leafletjs.com/reference-1.4.0.html#map-setview
		DefaultLat: 39.76632525654491,
		DefaultLon: -86.15615844726564,
		DefaultZoom: 12,
		MinZoom: 7,
		MaxZoom: 18,
		SpiderifyDistancePx: 10,
		SpiderifyLegThicknessPx: 3,
		SpiderifyLegColorNormal: 'rgba(var(--brand-grey-dark),0.6)',
		SpiderifyLegColorFocus: 'rgba(var(--brand-grey-dark),1)',
	},
	Table: {
		pagination: [5, 15, 50, 100],
		pollingDelayMs: 10 * MILLISECONDS_PER_SECOND,
		defaultColumns: [
			'rampName',
			'rampStatus',
			'rampRoute',
			'rampMileMarker',
			'rampDirection',
			'meteringPlan',
		] as typeof rampMeterColumns[number][],
	},
};

export const ConfigRampMeterApi = {
	getRampMetersEndpoint(): string {
		return 'rampmeters';
	},
	rampMeterPollingRate: 60 * MILLISECONDS_PER_SECOND,
};
