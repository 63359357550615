import { css } from 'lit';
import { Action } from 'redux';
import HTTPMethod from 'http-method-enum';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from './APIRequest';
import { ConfigRampMeterApi } from '../config/ConfigRampMeter';
import { APIConfig, DebuggingConfig } from '../config/ConfigCARSx';

import { RampMeterDto } from '../../typings/api';
import { ThunkActionRoot } from './redux-store';

export enum RampMeterAPIError {
	GetRampMeters = 'GetRampMeters',
}

//	ACTION TYPES

export enum RampMeterActionType {
	START_POLLING_RAMP_METERS = 'START_POLLING_RAMP_METERS',
	STOP_POLLING_RAMP_METERS = 'STOP_POLLING_RAMP_METERS',
	GET_RAMP_METERS = 'GET_RAMP_METERS',
	SET_RAMP_METERS = 'SET_RAMP_METERS',
}

type GetRampMeters = Action<RampMeterActionType.GET_RAMP_METERS>;

interface SetRampMeters extends Action<RampMeterActionType.SET_RAMP_METERS> {
	ramps: RampMeterDto[];
}

export type RampMeterAction = GetRampMeters | SetRampMeters;

export type RampMeterState = {
	errors?: {
		[key in RampMeterAPIError]?: {
			[key in APIError]?: boolean;
		};
	};
	ramps?: RampMeterDto[];
};

export const RAMP_METER_STATE_INITIAL: RampMeterState = {
	errors: {},
	ramps: undefined,
};

export const getRampMeters =
	(): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: RampMeterActionType.GET_RAMP_METERS });
		//  append new entry for each item
		const url = new URL(ConfigRampMeterApi.getRampMetersEndpoint(), APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);
		if (apiRequestReturn.response?.ok === true) {
			try {
				const ramps: RampMeterDto[] = (await apiRequestReturn.response.json()) as RampMeterDto[];
				dispatch({
					type: RampMeterActionType.SET_RAMP_METERS,
					ramps,
				});
			} catch (error) {
				apiRequestReturn.apiError = APIError.ResponseUnparseable;
				if (DebuggingConfig.showConsoleLogs) {
					console.error(
						`error parsing response from "${apiRequestReturn.request?.url as string}"`,
						error,
					);
				}
			}
		}
		return apiRequestReturn;
	};

let pollingRampMetersTimeout: ReturnType<typeof setTimeout> | undefined;

export const startPollingRamps =
	(
		pollingDelay: number = ConfigRampMeterApi.rampMeterPollingRate,
	): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		await dispatch(getRampMeters());
		if (pollingRampMetersTimeout === undefined) {
			pollingRampMetersTimeout = setTimeout(() => {
				void dispatch(startPollingRamps(pollingDelay));
			}, pollingDelay);
			dispatch({
				type: RampMeterActionType.START_POLLING_RAMP_METERS,
			});
		}
	};

//	REDUCER

export const RampMeterReducer = (
	state: RampMeterState = RAMP_METER_STATE_INITIAL,
	action: RampMeterAction | undefined = undefined,
): RampMeterState => {
	if (action === undefined) {
		return state;
	}
	switch (action.type) {
		case RampMeterActionType.SET_RAMP_METERS:
			return {
				...state,
				ramps: action.ramps,
			};
		default:
			return state;
	}
};
