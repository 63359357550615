import { css } from 'lit';
import { AnyAction, Store, applyMiddleware, combineReducers, createStore } from 'redux';
import thunk, { ThunkAction, ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import { LazyStore, lazyReducerEnhancer } from 'pwa-helpers/lazy-reducer-enhancer';
import {
	IBrowser,
	ICalculateResponsiveStateAction,
	createResponsiveStateReducer,
	responsiveStoreEnhancer,
} from 'redux-responsive';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
	LOADER_ANIM_STATE_INITIAL,
	LoaderAnimAction,
	LoaderAnimReducer,
	LoaderAnimState,
} from './redux-loaderAnim';
import { MAP_STATE_INITIAL, MapAction, MapReducer, MapState } from './redux-map';
import middlewareErrorCatcher from './error-catcher';
import { Breakpoint, MobileBreakpoint } from '../constants';
import { UI_STATE_INITIAL, UiAction, UiReducer, UiState } from './redux-ui';
import { CCTVReducer, CCTVState, CCTV_STATE_INITIAL } from './redux-cctv';
import { RAMP_METER_STATE_INITIAL, RampMeterReducer, RampMeterState } from './redux-ramp';
import REMAction from './rem/rem-actions';
import REMReducer from './rem/rem-reducer';
import REMState, { REM_STATE_INITIAL } from './rem/rem-state';
import { HHReducer, HHState, HH_STATE_INITIAL } from './hh/redux-hh';
import { DMSReducer } from './dms/dms-reducer';
import { DMSState, DMS_STATE_INITIAL } from './dms/dms-state';
import { USER_STATE_INITIAL, UserAction, UserReducer, UserState } from './redux-user';
import {
	ROUTING_STATE_INITIAL,
	RoutingAction,
	RoutingReducer,
	RoutingState,
} from './redux-routing';
import { NETWORK_STATE_INITIAL, NetworkReducer, NetworkState } from './redux-network';
import MetricsState from './metrics/redux-metrics';
import { METRICS_STATE_INITIAL, MetricsReducer } from './metrics/redux-metrics';
import {
	USER_MANAGEMENT_STATE_INITIAL,
	UserManagementReducer,
	UserManagementState,
} from './user-management/user-management-reducer';
import { UserManagementAction } from './user-management/user-management-actions';

export type RootAction =
	| LoaderAnimAction
	| MapAction
	| UiAction
	| REMAction
	| UserAction
	| RoutingAction
	| UserManagementAction
	| AnyAction
	| ICalculateResponsiveStateAction;

// @ts-expect-error typing these plugins are nightmarish
const responsiveReducer = createResponsiveStateReducer(
	{
		[Breakpoint.mobile]: MobileBreakpoint,
		[Breakpoint.desktop]: null,
	},
	{
		infinity: Breakpoint.desktop,
	},
);

export type ResponsiveIBrowser = IBrowser<{
	[Breakpoint.mobile]: number;
	[Breakpoint.desktop]: number;
}>;

//	TODO: upgrade CCTV to take advantage of new module store space
export interface RootState {
	loaderAnim: LoaderAnimState;
	map: MapState;
	routing: RoutingState;
	responsive?: ResponsiveIBrowser;
	ui: UiState;
	cctv: CCTVState;
	dms: DMSState;
	hh: HHState;
	rem: REMState;
	rampmeter: RampMeterState;
	metrics: MetricsState;
	user: UserState;
	network: NetworkState;
	userManagement: UserManagementState;
}

const ROOT_STATE_INITIAL: RootState = {
	loaderAnim: LOADER_ANIM_STATE_INITIAL,
	map: MAP_STATE_INITIAL,
	routing: ROUTING_STATE_INITIAL,
	responsive: undefined,
	ui: UI_STATE_INITIAL,
	cctv: CCTV_STATE_INITIAL,
	dms: DMS_STATE_INITIAL,
	hh: HH_STATE_INITIAL,
	rem: REM_STATE_INITIAL,
	rampmeter: RAMP_METER_STATE_INITIAL,
	metrics: METRICS_STATE_INITIAL,
	user: USER_STATE_INITIAL,
	network: NETWORK_STATE_INITIAL,
	userManagement: USER_MANAGEMENT_STATE_INITIAL,
};

/**
 * @see https://github.com/reduxjs/redux-thunk/issues/103#issuecomment-395955111
 */

// https://github.com/reduxjs/redux-thunk/blob/master/test/typescript.ts
export type ThunkDispatchRoot = ThunkDispatch<RootState, void, RootAction>;
export type ThunkActionRoot<R = void> = ThunkAction<R, RootState, void, RootAction>;
export type ThunkResult<R> = ThunkAction<R, RootState, void, AnyAction>;
export type ThunkReturn = ThunkDispatch<RootState, undefined, AnyAction>;
export type GetState = () => RootState;

export type ThunkStoreRoot = Store<RootState, RootAction> &
	LazyStore & { dispatch: ThunkDispatchRoot };

export type MiddlewareNext = (arg0: RootAction) => RootState;

const middlewares = [thunk as ThunkMiddleware<RootState>, middlewareErrorCatcher];

const store = createStore(
	combineReducers({
		loaderAnim: LoaderAnimReducer,
		map: MapReducer,
		routing: RoutingReducer,
		responsive: responsiveReducer,
		ui: UiReducer,
		cctv: CCTVReducer,
		dms: DMSReducer,
		hh: HHReducer,
		rem: REMReducer,
		rampmeter: RampMeterReducer,
		metrics: MetricsReducer,
		user: UserReducer,
		network: NetworkReducer,
		userManagement: UserManagementReducer,
	}),
	ROOT_STATE_INITIAL,
	composeWithDevTools(
		lazyReducerEnhancer(combineReducers),
		responsiveStoreEnhancer,
		// @ts-expect-error typescript is never happy
		applyMiddleware(...middlewares),
	),
) as ThunkStoreRoot;

export default store;
