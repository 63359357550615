import { css } from 'lit';
/* eslint-disable max-lines */
import { Action } from 'redux';
import { MatchFunction, Path } from 'path-to-regexp';
import UniversalRouter, {
	Route,
	RouteContext,
	RouteError,
	RouteParams,
	RouteResult,
	Routes,
} from 'universal-router';
import { ThunkDispatch } from 'redux-thunk';
import { URLSearchParams } from 'url';
import pollGrafanaHealth from '@components/metrics/performance-dashboard-health-checker';
import { GrafanaConfig } from '@/config/ConfigDashboard';
import { RootAction, RootState, ThunkActionRoot } from './redux-store';
import { clearItems } from './redux-loaderAnim';
import ConfigCARSx, { DebuggingConfig } from '../config/ConfigCARSx';
import Authorization from '../rest/Authorization';
import {
	AppSection,
	Breakpoint,
	CCTVView,
	DMSView,
	HHView,
	MetricsView,
	ModalSection,
	REMView,
	RampMeterView,
} from '../constants';
import { getRoutes, pollSignQueue, pollSigns } from './dms/dms-actions';
import { startPollingREMEvent, startPollingREMEvents } from '../components/rem/RemPollingManager';
import { ConfigREMMap, ConfigREMTable } from '../config/ConfigREM';
import { ConfigRampMeter } from '../config/ConfigRampMeter';
import { setLoadingRoute, showMainBanner } from './redux-ui';
import { getHHFields, pollHH } from './hh/redux-hh';
import { startPollingHH } from '../components/hh/HHPollingManager';
import { ConfigHHTable } from '../config/ConfigHH';
import {
	getDraftEventIcon,
	getEventDescription,
	getEventFields,
	getREMEvent,
	startNewREMDraftEvent,
} from './rem/rem-actions-event';
import { getUserPermissions, selectLandingPageForUser } from './redux-user';
import { userHasPermission } from './user-permissions';
import {
	EventStatus,
	LocationTypes,
	RoadEventDto,
	UserPermissions,
	getEmptyDraftEvent,
} from '../../typings/api';
import { getSign, getSignFields, getSignQueue } from './dms/dms-actions-sign';
import { getCameraRoles, getDevice } from './cctv/cctv-actions-device';
import { getGroups } from './dms/dms-actions-group';
import { getCustomMessages } from './dms/dms-actions-custom-message';
import { getSignGraphics } from './dms/dms-actions-graphic';
import {
	setCurrentlySelectedGridIndex,
	startPollingCCTVCameras,
	startPollingCCTVMonitors,
} from './redux-cctv';
import { startPollingRamps } from './redux-ramp';
import { getMetricsHelper } from './metrics/redux-metrics';
import {
	getMetricsEvent,
	getMetricsEventTimeline,
	getMetricsHHTimeline,
} from './metrics/redux-metrics';
import { getREMEventCurrentEditors } from './rem/rem-actions-event-editors';
import {
	getNamedPointsForRoute,
	getREMEventRouteGeometry,
	getRampsForRoute,
	handleNewLocationDetailsForREMEvent,
} from './rem/rem-actions-event-location';
import {
	checkPasswordResetToken,
	getPermissionTypes,
	pollRolesWithUsers,
} from './user-management/user-management-actions';
import { ConfigUserManagement } from '../config/ConfigLogin';
import { NotificationErrorType } from '../../typings/shared-types';
import { CCTVActionType } from './cctv/cctv-actions';

//	STATE

export interface RoutingState {
	// FIXME: under what circumstances should this be null?
	page?: AppSection | null;
	group?: string | null;
	view?: string | null;
	action?: string | null;
	id?: number | null;
	modalView?: ModalSection | null;
	modalId?: number | null;
	// last routing state
	lastURL?: string;
}

export const ROUTING_STATE_INITIAL: RoutingState = {
	// page: undefined,
};

//	ACTION TYPING

export enum RoutingType {
	SET_PAGE = 'SET_PAGE',
	SET_MODAL = 'SET_MODAL',
}

interface Navigate extends Action<typeof RoutingType.SET_PAGE> {
	page?: AppSection;
	group?: string;
	view?: string;
	id?: number;
	action?: string | null;
}

interface NavigateModal extends Action<typeof RoutingType.SET_MODAL> {
	modalView?: ModalSection;
	modalId?: number;
}

class CancelToken {
	isCancellationRequested = false;

	cancel() {
		this.isCancellationRequested = true;
	}
}

export type RoutingAction = Navigate | NavigateModal;

export interface SafeRoute<R, C, T> {
	path?: Path;
	name?: string;
	parent?: Route<R, C> | null;
	children?: Array<SafeRoute<R, C, T>> | null;
	nav?: (searchParams: URLSearchParams, params: T) => Partial<Navigate>;
	action?: (context: RouteContext<R, C> & C, params: T) => RouteResult<R>;
	match?: MatchFunction<RouteParams>;
}
export interface OwRouteContext {
	dispatch: ThunkDispatch<RootState, undefined, RootAction>;
	getState: () => RootState;
	searchParams: URLSearchParams;
	cancelToken: CancelToken;
}

export type RouteReturn =
	| { redirect?: string }
	/** continue to try to match the next path */
	| null
	| void
	/** Do no further processing of route */
	| false
	/** do not continue to try to match */
	| true;

type CCTVParams = { group: string; view: string };

type Params = CCTVParams & { id: string; action: string; token: string };

export const NO_ID_INDICATOR = '_';

//	ACTIONS

const CCTV_ROUTES: Array<SafeRoute<RouteReturn, OwRouteContext, Params>> = [
	{
		path: ConfigCARSx.Pages.cctv.route,
		action({ getState }): RouteReturn {
			if (userHasPermission(UserPermissions.CCTV_ACCESS) === false) {
				if (DebuggingConfig.showConsoleLogs) {
					console.warn('CCTV access denied');
				}
				return { redirect: ConfigCARSx.Pages.login.route };
			}
			const mediaType = getState().responsive?.mediaType as Breakpoint;
			const defaultViewSegment =
				ConfigCARSx.Pages.cctv.defaultRouteSegments?.view?.[mediaType] || CCTVView.map;
			return {
				redirect: `${ConfigCARSx.Pages.cctv.route}/${NO_ID_INDICATOR}/${defaultViewSegment}`,
			};
		},
	},
	{
		path: `${ConfigCARSx.Pages.cctv.route}/:group`,
		async action({ dispatch, getState }, { group: newGroup }): Promise<RouteReturn | void> {
			let state = getState();
			const mediaType = state.responsive?.mediaType as Breakpoint;
			const { page: previousPage, group: previousGroup, view: viewInState } = state.routing;

			if (previousGroup === NO_ID_INDICATOR && newGroup === NO_ID_INDICATOR) {
				if (DebuggingConfig.showConsoleLogs) {
					console.error(`infinite redirect loop: CCTV module can't find a monitor to display.`);
					//	it would be nice to have something more user-friendly happen here - 'load monitors' error and prompt to refresh?
				}
			}

			const defaultViewSegment =
				ConfigCARSx.Pages.cctv.defaultRouteSegments?.view?.[mediaType] || CCTVView.map;

			let view = defaultViewSegment;

			if (
				viewInState !== null &&
				viewInState !== undefined &&
				// verify that view value in state is valid for cctv (i.e. not leftover from another route)
				Object.values(CCTVView).includes(viewInState as CCTVView) &&
				(viewInState as CCTVView) !== CCTVView.editdevice
			) {
				view = viewInState;
			}

			if (previousGroup !== newGroup) {
				//	if the monitor changes, then the grid selection state is reset
				dispatch(setCurrentlySelectedGridIndex(undefined));
			}

			if (previousPage !== AppSection.CCTV) {
				await Promise.all([
					dispatch(startPollingCCTVMonitors()),
					dispatch(startPollingCCTVCameras()),
					import(`../components/cctv/cctv`),
				]);
			}

			state = getState();

			if (state.cctv.monitors?.length === 0) {
				return undefined;
			}

			const firstMonitor = state.cctv.monitors?.[0];

			const foundMonitor = state.cctv.monitors?.find((monitor) => monitor.name === newGroup);

			if (!foundMonitor) {
				if (DebuggingConfig.showConsoleLogs) {
					console.error(
						`unrecognized monitor name "${newGroup}", defaulting to first monitor in list instead.`,
					);
				}
				return {
					redirect: `${ConfigCARSx.Pages.cctv.route}/${
						firstMonitor?.name ?? NO_ID_INDICATOR
					}/${view}`,
				};
			}

			//	if :group is '_' that's the placeholder value for if CCTV is navigated to, but groups have not yet been loaded
			if (newGroup === NO_ID_INDICATOR) {
				if (state.cctv.monitors === undefined) {
					if (DebuggingConfig.showConsoleLogs) {
						console.warn('CCTV landing page redirect failed, no monitors currently loaded');
					}
				} else if (state.cctv.monitors.length === 0) {
					if (DebuggingConfig.showConsoleLogs) {
						console.warn('CCTV landing page redirect failed, no monitors returned by API');
					}
				} else if (firstMonitor?.name) {
					//	this is the normal redirect process to go from cctv/_ to the first monitor
					return {
						redirect: `${ConfigCARSx.Pages.cctv.route}/${firstMonitor.name}/${view}`,
					};
				}
			}

			const pageConfig = ConfigCARSx.Pages[AppSection.CCTV];
			document.title = `${ConfigCARSx.AppTitle} - ${pageConfig.pageTitle}`;
			return undefined;
		},
		children: [
			{
				path: '',
				action({ getState }, { group }): RouteReturn {
					const mediaType = getState().responsive?.mediaType as Breakpoint;
					const defaultViewSegment =
						ConfigCARSx.Pages.cctv.defaultRouteSegments?.view?.[mediaType] || CCTVView.map;
					return { redirect: `${ConfigCARSx.Pages.cctv.route}/${group}/${defaultViewSegment}` };
				},
			},
			{
				path: `/${CCTVView.map}`,
				nav(_, { group }): Partial<Navigate> {
					return {
						page: AppSection.CCTV,
						view: CCTVView.map,
						group,
					};
				},
				action({ pathname, getState }, { group }): RouteReturn {
					if (group !== NO_ID_INDICATOR && getState().routing.group === NO_ID_INDICATOR) {
						// Replace the loading step with the new group id.
						window.history.replaceState({}, '', pathname);
					}
					return true;
				},
			},
			{
				path: `/${CCTVView.table}`,
				nav(_, { group }): Partial<Navigate> {
					return {
						page: AppSection.CCTV,
						view: CCTVView.table,
						group,
					};
				},
				action({ pathname, getState }, { group }): RouteReturn {
					if (group !== NO_ID_INDICATOR && getState().routing.group === NO_ID_INDICATOR) {
						// Replace the loading step with the new group id.
						window.history.replaceState({}, '', pathname);
					}

					return true;
				},
			},
			{
				path: `/${CCTVView.editdevice}`,
				nav(_, { group }): Partial<Navigate> {
					return {
						page: AppSection.CCTV,
						view: CCTVView.editdevice,
						group,
					};
				},
				async action({ pathname, getState, dispatch }, { group }): Promise<RouteReturn> {
					if (group !== NO_ID_INDICATOR && getState().routing.group === NO_ID_INDICATOR) {
						// Replace the loading step with the new group id.
						window.history.replaceState({}, '', pathname);
					}
					await Promise.all([
						dispatch(getRoutes()),
						dispatch(getCameraRoles()),
						dispatch({
							type: CCTVActionType.SET_EXISTING_DEVICE,
							undefined,
						}),
					]);
					return true;
				},
			},
			{
				path: `/${CCTVView.editdevice}/:id`,
				nav(_, { group }): Partial<Navigate> {
					return {
						page: AppSection.CCTV,
						view: CCTVView.editdevice,
						group,
					};
				},
				async action({ dispatch }, { id: rawId }): Promise<RouteReturn> {
					const id = parseInt(rawId, 10);
					await Promise.all([
						dispatch(getRoutes()),
						dispatch(getCameraRoles()),
						dispatch(getDevice(id)),
					]);
					return true;
				},
			},
		],
	},
];

const DMS_ROUTES: Array<SafeRoute<RouteReturn, OwRouteContext, Params>> = [
	{
		path: ConfigCARSx.Pages.dms.route,
		async action(): Promise<RouteReturn> {
			if (userHasPermission(UserPermissions.DMS_ACCESS) === false) {
				if (DebuggingConfig.showConsoleLogs) {
					console.warn('DMS access denied');
				}
				return { redirect: ConfigCARSx.Pages.login.route };
			}

			const pageConfig = ConfigCARSx.Pages[AppSection.DMS];

			document.title = `${ConfigCARSx.AppTitle} - ${pageConfig.pageTitle}`;
			await import(`../components/dms/dms`);

			return undefined;
		},
		children: [
			{
				path: '',
				action({ getState }): RouteReturn {
					const mediaType = getState().responsive?.mediaType as Breakpoint;
					const defaultViewSegment =
						ConfigCARSx.Pages.dms.defaultRouteSegments?.view?.[mediaType] || DMSView.table;
					return { redirect: `${ConfigCARSx.Pages.dms.route}/${defaultViewSegment}` };
				},
			},
			{
				path: `/${DMSView.map}`,
				nav(): Partial<Navigate> {
					return {
						page: AppSection.DMS,
						view: DMSView.map,
					};
				},
				action({ dispatch }): RouteReturn {
					void dispatch(pollSigns(true));

					return true;
				},
			},
			{
				path: `/${DMSView.table}`,
				nav(): Partial<Navigate> {
					return {
						page: AppSection.DMS,
						view: DMSView.table,
					};
				},
				async action({ dispatch }): Promise<RouteReturn> {
					await dispatch(pollSigns(true));

					return true;
				},
			},
			{
				path: `/${DMSView.group}`,
				nav(): Partial<Navigate> {
					return {
						page: AppSection.DMS,
						view: DMSView.group,
					};
				},
				async action({ dispatch }): Promise<RouteReturn> {
					await dispatch(pollSigns(true));

					return true;
				},
			},
			{
				path: `/${DMSView['image-library']}`,
				nav(): Partial<Navigate> {
					return {
						page: AppSection.DMS,
						view: DMSView['image-library'],
					};
				},
				async action({ dispatch }): Promise<RouteReturn> {
					if (userHasPermission(UserPermissions.DMS_CAN_MANAGE_GRAPHICS) === false) {
						if (DebuggingConfig.showConsoleLogs) {
							console.warn('user lacks permission to manage DMS graphics');
						}
						return { redirect: `${ConfigCARSx.Pages.dms.route}` };
					}
					await dispatch(getSignGraphics());
					return true;
				},
			},
			{
				path: `/${DMSView.sign}`,
				nav(): Partial<Navigate> {
					return {
						page: AppSection.DMS,
						view: DMSView.sign,
					};
				},
				async action({ dispatch }): Promise<RouteReturn> {
					await Promise.all([
						dispatch(getRoutes()),
						dispatch(getGroups()),
						dispatch(getSignFields()),
					]);
				},
				children: [
					{
						path: '/new',
						nav(): Partial<Navigate> {
							return {
								page: AppSection.DMS,
								view: DMSView.sign,
							};
						},
						action(): RouteReturn {
							if (userHasPermission(UserPermissions.DMS_CAN_MANAGE_SIGNS) === false) {
								if (DebuggingConfig.showConsoleLogs) {
									console.warn('user lacks permission to add new dms sign');
								}
								return { redirect: `${ConfigCARSx.Pages.dms.route}` };
							}

							return true;
						},
					},
					{
						path: '/:id?',
						nav(_, { id: rawId }): Partial<Navigate> {
							const id = parseInt(rawId, 10);

							return {
								page: AppSection.DMS,
								view: DMSView.sign,
								id,
							};
						},
						async action({ dispatch }, { id: rawId }): Promise<RouteReturn> {
							const id = parseInt(rawId, 10);

							if (Number.isNaN(id)) {
								return { redirect: `${ConfigCARSx.Pages.dms.route}/${DMSView.sign}/new` };
							}

							await dispatch(getSign(id));

							return true;
						},
					},
				],
			},
			{
				path: `/${DMSView['sign-queue']}/:id?`,
				nav(_, params): Partial<Navigate> {
					const id = parseInt(params.id, 10);

					return {
						page: AppSection.DMS,
						view: DMSView['sign-queue'],
						id,
					};
				},
				async action({ dispatch }, params): Promise<RouteReturn> {
					const id = parseInt(params.id, 10);
					await Promise.all([
						dispatch(getSignQueue(id)),
						dispatch(getSign(id)),
						dispatch(getCustomMessages()),
						dispatch(getSignGraphics()),
						dispatch(pollSignQueue(id)),
					]);

					return true;
				},
			},
			{
				path: `/${DMSView['custom-messages']}`,
				nav(): Partial<Navigate> {
					return {
						page: AppSection.DMS,
						view: DMSView['custom-messages'],
					};
				},
				async action({ dispatch }): Promise<RouteReturn> {
					if (userHasPermission(UserPermissions.DMS_CAN_MANAGE_MESSAGES) === false) {
						if (DebuggingConfig.showConsoleLogs) {
							console.warn('user lacks permission to manage DMS messages');
						}
						return { redirect: `${ConfigCARSx.Pages.dms.route}` };
					}
					await Promise.all([dispatch(getCustomMessages()), dispatch(getSignGraphics())]);

					return true;
				},
			},
			{
				path: `/${DMSView['custom-message']}`,
				children: [
					{
						path: '/new',
						nav(): Partial<Navigate> {
							return {
								page: AppSection.DMS,
								view: DMSView['custom-message'],
							};
						},
						async action({ dispatch }): Promise<RouteReturn> {
							if (userHasPermission(UserPermissions.DMS_CAN_MANAGE_MESSAGES) === false) {
								if (DebuggingConfig.showConsoleLogs) {
									console.warn('user lacks permission to add new dms message');
								}
								return { redirect: `${ConfigCARSx.Pages.dms.route}` };
							}

							await dispatch(getSignGraphics());
							return true;
						},
					},
					{
						path: '/:id?',
						nav(_, { id: rawId }): Partial<Navigate> {
							const id = parseInt(rawId, 10);

							return {
								page: AppSection.DMS,
								view: DMSView['custom-message'],
								id,
							};
						},
						async action({ dispatch }, { id: rawId }): Promise<RouteReturn> {
							const id = parseInt(rawId, 10);
							if (Number.isNaN(id)) {
								return {
									redirect: `${ConfigCARSx.Pages.dms.route}/${DMSView['custom-message']}/new`,
								};
							}
							await Promise.all([dispatch(getCustomMessages([id])), dispatch(getSignGraphics())]);

							return true;
						},
					},
				],
			},
		],
	},
];

const REM_ROUTES: Array<SafeRoute<RouteReturn, OwRouteContext, Params>> = [
	{
		path: ConfigCARSx.Pages.rem.route,
		action(): RouteReturn {
			if (userHasPermission(UserPermissions.REM_ACCESS) === false) {
				if (DebuggingConfig.showConsoleLogs) {
					console.warn('REM access denied');
				}
				return { redirect: ConfigCARSx.Pages.login.route };
			}
			const pageConfig = ConfigCARSx.Pages[AppSection.REM];
			document.title = `${ConfigCARSx.AppTitle} - ${pageConfig.pageTitle}`;
			return undefined;
		},
		children: [
			{
				path: '',
				action({ getState }): RouteReturn {
					const mediaType = getState().responsive?.mediaType as Breakpoint;
					const defaultViewSegment =
						ConfigCARSx.Pages.rem.defaultRouteSegments?.view?.[mediaType] || REMView.table;
					return { redirect: `${ConfigCARSx.Pages.rem.route}/${defaultViewSegment}` };
				},
			},
			{
				path: `/${REMView.map}`,
				nav(): Partial<Navigate> {
					return {
						page: AppSection.REM,
						view: REMView.map,
					};
				},
				async action({ dispatch }): Promise<RouteReturn> {
					await Promise.all([
						dispatch(startPollingREMEvents(ConfigREMMap.pollingDelayMs)),
						import(`../components/rem/rem`),
					]);
					return true;
				},
			},
			{
				path: `/${REMView.table}`,
				nav(): Partial<Navigate> {
					return {
						page: AppSection.REM,
						view: REMView.table,
					};
				},
				async action({ dispatch }): Promise<RouteReturn> {
					await Promise.all([
						dispatch(startPollingREMEvents(ConfigREMTable.pollingDelayMs, true)),
						import(`../components/rem/rem`),
					]);
					return true;
				},
			},
			{
				path: `/${REMView.event}`,
				children: [
					{
						path: '/new',
						nav(): Partial<Navigate> {
							return {
								page: AppSection.REM,
								view: REMView.event,
							};
						},
						async action({ dispatch, searchParams }): Promise<RouteReturn> {
							if (userHasPermission(UserPermissions.REM_READONLY) === true) {
								if (DebuggingConfig.showConsoleLogs) {
									console.warn('user lacks permission to add new rem event');
								}
								return { redirect: `${ConfigCARSx.Pages.rem.route}` };
							}

							const promises: Promise<unknown>[] = [
								import(`../components/rem/rem`),
								dispatch(getEventFields()),
							];

							const draftEvent = getEmptyDraftEvent();

							if (searchParams.has('route')) {
								const route = searchParams.get('route');
								if (route) {
									draftEvent.route = route;
									promises.push(dispatch(getNamedPointsForRoute(route)));
									promises.push(dispatch(getREMEventRouteGeometry(route)));
								}
							}
							if (searchParams.has('startMileMarker')) {
								const startMileMarker = searchParams.get('startMileMarker');

								if (startMileMarker) {
									draftEvent.startMileMarker = parseFloat(startMileMarker);
								}
							}
							if (searchParams.has('linkedEventId')) {
								const linkedEventId = searchParams.get('linkedEventId');

								if (linkedEventId) {
									draftEvent.linkedEvents = [{ linkedEventId: parseInt(linkedEventId, 10) }];
								}
							}
							if (draftEvent.route && draftEvent.startMileMarker) {
								void handleNewLocationDetailsForREMEvent(
									dispatch,
									draftEvent.route,
									draftEvent.startMileMarker,
									NaN,
								);
							}
							dispatch(startNewREMDraftEvent(draftEvent));

							await Promise.all(promises);

							return true;
						},
					},
					{
						path: '/:id?',
						nav(_, params): Partial<Navigate> {
							const id = parseInt(params.id, 10);

							return {
								page: AppSection.REM,
								view: REMView.event,
								id,
							};
						},
						async action(
							{ getState, dispatch, searchParams, cancelToken },
							params,
						): Promise<RouteReturn> {
							const id = parseInt(params.id, 10);
							if (Number.isNaN(id)) {
								return { redirect: `${ConfigCARSx.Pages.rem.route}/${REMView.event}/new` };
							}
							void startPollingREMEvent(id, ConfigREMTable.pollingDelayMs);

							// if the event isn't already in the list try fetching it by id
							await Promise.all([
								import(`../components/rem/rem`),
								dispatch(getREMEvent(id)),
								dispatch(getEventFields()),
								dispatch(getREMEventCurrentEditors(id)),
							]);

							const event: RoadEventDto = getState().rem.draftEvent as RoadEventDto;
							if (event) {
								void Promise.all([
									dispatch(getDraftEventIcon(event)),
									dispatch(getEventDescription(event)),
								]);
								const promises: Promise<unknown>[] = [];
								if (event.route && event.eventLocation === LocationTypes.RAMP) {
									promises.push(dispatch(getRampsForRoute(event.route)));
								}
								if (event.route && event.eventLocation === LocationTypes.MAJOR_ROAD) {
									promises.push(dispatch(getREMEventRouteGeometry(event.route)));
									promises.push(dispatch(getNamedPointsForRoute(event.route)));
								}
								await Promise.all(promises);
							} else {
								dispatch(startNewREMDraftEvent());
								return { redirect: `${ConfigCARSx.Pages.rem.route}/${REMView.event}/new` };
							}
							if (
								(searchParams.has('read-only') || event?.eventStatus === EventStatus.COMPLETED) &&
								!cancelToken.isCancellationRequested
							) {
								//	ideally this should be dispatch(setREMReadOnlyMode())
								//	but circular dependency plugin throws a false positive over it so 🤷‍♂️
								dispatch({ type: 'SET_REM_READ_ONLY_MODE', readOnlyMode: true });
							} else {
								dispatch({ type: 'SET_REM_READ_ONLY_MODE', readOnlyMode: false });
							}

							return true;
						},
					},
				],
			},
		],
	},
];

const RAMP_METER_ROUTES: Array<SafeRoute<RouteReturn, OwRouteContext, Params>> = [
	{
		path: ConfigCARSx.Pages['ramp-meter'].route,
		action(): RouteReturn {
			const pageConfig = ConfigCARSx.Pages[AppSection.RAMP_METER];
			document.title = `${ConfigCARSx.AppTitle} - ${pageConfig.pageTitle}`;
			return undefined;
		},
		children: [
			{
				path: '',
				action(): RouteReturn {
					return { redirect: `${ConfigCARSx.Pages['ramp-meter'].route}/table` };
				},
			},
			{
				path: `/${RampMeterView.table}`,
				nav(): Partial<Navigate> {
					return {
						page: AppSection.RAMP_METER,
						view: RampMeterView.table,
					};
				},
				async action({ dispatch }): Promise<RouteReturn> {
					await Promise.all([
						dispatch(startPollingRamps(ConfigRampMeter.Table.pollingDelayMs)),
						import(`../components/ramp-meter/ramp-meter`),
					]);
					return true;
				},
			},
			{
				path: `/${RampMeterView.map}`,
				nav(): Partial<Navigate> {
					return {
						page: AppSection.RAMP_METER,
						view: RampMeterView.map,
					};
				},
				async action({ dispatch }): Promise<RouteReturn> {
					await Promise.all([dispatch(startPollingRamps(ConfigRampMeter.Table.pollingDelayMs))]);
					return true;
				},
			},
		],
	},
];

const HH_ROUTES: Array<SafeRoute<RouteReturn, OwRouteContext, Params>> = [
	{
		path: ConfigCARSx.Pages[AppSection.HH].route,
		async action({ dispatch }): Promise<RouteReturn> {
			if (userHasPermission(UserPermissions.HH_ACCESS) === false) {
				if (DebuggingConfig.showConsoleLogs) {
					console.warn('HH access denied');
				}
				return { redirect: ConfigCARSx.Pages.login.route };
			}
			const pageConfig = ConfigCARSx.Pages[AppSection.HH];
			document.title = `${ConfigCARSx.AppTitle} - ${pageConfig.pageTitle}`;
			await dispatch(getHHFields());
			return undefined;
		},
		children: [
			{
				path: '',
				action({ getState }): RouteReturn {
					const mediaType = getState().responsive?.mediaType as Breakpoint;

					const defaultViewSegment =
						ConfigCARSx.Pages[AppSection.HH].defaultRouteSegments?.view?.[mediaType] ||
						HHView.table;
					return { redirect: `${ConfigCARSx.Pages[AppSection.HH].route}/${defaultViewSegment}` };
				},
			},
			{
				path: `/${HHView.table}`,
				nav(): Partial<Navigate> {
					return {
						page: AppSection.HH,
						view: HHView.table,
					};
				},
				async action({ dispatch }): Promise<RouteReturn> {
					await Promise.all([dispatch(pollHH()), import(`../components/hh/hh`)]);
					return true;
				},
			},
			{
				path: `/${HHView.helper}`,
				children: [
					{
						path: '/:id?/:action?',
						nav(_, params): Partial<Navigate> {
							const id = parseInt(params.id, 10);
							const { action } = params;
							return {
								page: AppSection.HH,
								view: HHView.helper,
								id,
								action,
							};
						},
						async action({ dispatch }, params): Promise<RouteReturn> {
							const id = parseInt(params.id, 10);
							const { action } = params;

							await Promise.all([
								dispatch(pollHH()),
								startPollingHH(id, ConfigHHTable.pollingDelayMs),
								import(`../components/hh/hh`),
							]);

							dispatch({
								type: RoutingType.SET_PAGE,
								page: AppSection.HH,
								view: HHView.helper,
								id,
								action,
							});
							return true;
						},
					},
				],
			},
		],
	},
];

const METRICS_ROUTES: Array<SafeRoute<RouteReturn, OwRouteContext, Params>> = [
	{
		path: ConfigCARSx.Pages[AppSection.METRICS].route,
		action(): RouteReturn {
			if (userHasPermission(UserPermissions.METRICS_ACCESS) === false) {
				if (DebuggingConfig.showConsoleLogs) {
					console.warn('Metrics access denied');
				}
				return { redirect: ConfigCARSx.Pages.login.route };
			}
			const pageConfig = ConfigCARSx.Pages[AppSection.METRICS];
			document.title = `${ConfigCARSx.AppTitle} - ${pageConfig.pageTitle}`;
			return undefined;
		},
		children: [
			{
				path: '',
				action({ getState }): RouteReturn {
					const mediaType = getState().responsive?.mediaType as Breakpoint;
					const defaultViewSegment =
						ConfigCARSx.Pages[AppSection.METRICS].defaultRouteSegments?.view?.[mediaType] ||
						MetricsView.events;
					return {
						redirect: `${ConfigCARSx.Pages[AppSection.METRICS].route}/${defaultViewSegment}`,
					};
				},
			},
			{
				path: `/${MetricsView.events}`,
				nav(): Partial<Navigate> {
					return {
						page: AppSection.METRICS,
						view: MetricsView.events,
					};
				},
				async action({ dispatch }): Promise<RouteReturn> {
					await Promise.all([dispatch(getEventFields()), import(`../components/metrics/metrics`)]);

					return true;
				},
			},
			{
				path: `/${MetricsView.helpers}`,
				nav(): Partial<Navigate> {
					return {
						page: AppSection.METRICS,
						view: MetricsView.helpers,
					};
				},
				async action({ dispatch }): Promise<RouteReturn> {
					await Promise.all([dispatch(getHHFields()), import(`../components/metrics/metrics`)]);

					return true;
				},
			},
			{
				path: `/${MetricsView.helper}/:id?`,
				nav(_, params): Partial<Navigate> {
					const id = parseInt(params.id, 10);

					return {
						page: AppSection.METRICS,
						view: MetricsView.helper,
						id,
					};
				},
				async action({ dispatch, searchParams, cancelToken }, params): Promise<RouteReturn> {
					const id = parseInt(params.id, 10);

					await Promise.all([
						dispatch(getMetricsHelper(id)),
						dispatch(getMetricsHHTimeline(id)),
						import(`../components/metrics/metrics`),
					]);

					if (searchParams.has('start') && !cancelToken.isCancellationRequested) {
						const start = parseInt(searchParams.get('start') ?? '', 10);
						dispatch({ type: 'SET_METRICS_HH_START', start });
					}
					if (searchParams.has('end') && !cancelToken.isCancellationRequested) {
						const end = parseInt(searchParams.get('end') ?? '', 10);
						dispatch({ type: 'SET_METRICS_HH_END', end });
					}

					return true;
				},
			},
			{
				path: `/${MetricsView.event}/:id?`,
				nav(_, params): Partial<Navigate> {
					const id = parseInt(params.id, 10);

					return {
						page: AppSection.METRICS,
						view: MetricsView.event,
						id,
					};
				},
				async action({ dispatch }, params): Promise<RouteReturn> {
					const id = parseInt(params.id, 10);

					const event = await dispatch(getMetricsEvent(id));

					if (event === undefined) {
						return { redirect: ConfigCARSx.Pages.metrics.route };
					}

					void dispatch(getDraftEventIcon(event));

					await Promise.all([
						import(`../components/metrics/metrics`),
						dispatch(getEventFields()),
						dispatch(getMetricsEventTimeline(id)),
					]);

					return true;
				},
			},
			{
				path: `/${MetricsView.dashboard}`,
				nav(): Partial<Navigate> {
					return {
						page: AppSection.METRICS,
						view: MetricsView.dashboard,
					};
				},
				async action({ dispatch }): Promise<RouteReturn> {
					if (!GrafanaConfig.enabled) {
						return { redirect: ConfigCARSx.Pages.metrics.route };
					}

					const isGrafanaServerHealthy =
						!GrafanaConfig.performHealthCheck ?? (await pollGrafanaHealth());

					if (!isGrafanaServerHealthy) {
						dispatch(
							showMainBanner(
								NotificationErrorType.ERROR,
								{
									title: `Failed to connect to dashboard server`,
								},
								5000,
							),
						);
						return { redirect: ConfigCARSx.Pages.metrics.route };
					}
					await Promise.all([import('../components/metrics/metrics'), dispatch(getEventFields())]);

					return true;
				},
			},
		],
	},
];

const USER_GROUPS_ROUTES: Array<SafeRoute<RouteReturn, OwRouteContext, Params>> = [
	{
		path: ConfigCARSx.Pages[AppSection.USER_GROUPS].route,
		action(): RouteReturn {
			if (
				userHasPermission(UserPermissions.USER_MANAGEMENT_ACCESS) === false ||
				ConfigUserManagement.showManageUsersPage === false
			) {
				if (DebuggingConfig.showConsoleLogs) {
					console.warn('user management access denied');
				}
				return { redirect: ConfigCARSx.Pages.login.route };
			}
			const pageConfig = ConfigCARSx.Pages[AppSection.USER_GROUPS];
			document.title = `${ConfigCARSx.AppTitle} - ${pageConfig.pageTitle}`;
			return undefined;
		},
		children: [
			{
				path: '',
				nav(_, _params): Partial<Navigate> {
					return {
						page: AppSection.USER_GROUPS,
					};
				},
				async action({ dispatch }) {
					await Promise.all([
						import(`../components/user-management/user-management`),
						dispatch(pollRolesWithUsers(true)),
						dispatch(getPermissionTypes()),
					]);
					return true;
				},
			},
		],
	},
];

const APP_SECTION_ROUTES: Record<
	AppSection,
	Array<SafeRoute<RouteReturn, OwRouteContext, Params>>
> = {
	[AppSection.LOGIN]: [],
	[AppSection.CCTV]: CCTV_ROUTES,
	[AppSection.DMS]: DMS_ROUTES,
	[AppSection.REM]: REM_ROUTES,
	[AppSection.RAMP_METER]: RAMP_METER_ROUTES,
	[AppSection.HH]: HH_ROUTES,
	[AppSection.METRICS]: METRICS_ROUTES,
	[AppSection.USER_GROUPS]: USER_GROUPS_ROUTES,
};

let routesList: Array<SafeRoute<RouteReturn, OwRouteContext, Params>> = [
	{
		action({ pathname }): RouteReturn {
			if (!pathname.includes(ConfigCARSx.Pages[AppSection.LOGIN].route) && !Authorization.JWT) {
				return { redirect: ConfigCARSx.Pages[AppSection.LOGIN].route };
			}
			return null;
		},
	},
	{
		path: '',
		action(): RouteReturn {
			return { redirect: selectLandingPageForUser() };
		},
	},
	{
		path: ConfigCARSx.Pages[AppSection.LOGIN].route,
		nav(): Partial<Navigate> {
			if (Authorization.JWT) {
				// Prevent flashing of blue bg multiple times
				return { page: '' as AppSection };
			}
			return {
				page: AppSection.LOGIN,
			};
		},
		async action(): Promise<RouteReturn> {
			const pageConfig = ConfigCARSx.Pages[AppSection.LOGIN];

			if (Authorization.JWT) {
				const landingPage = selectLandingPageForUser();

				return { redirect: landingPage };
			}
			document.title = `${ConfigCARSx.AppTitle} - ${pageConfig.pageTitle}`;
			await import(`../components/login/login`);

			return true;
		},
	},
	{
		path: '/logout',
		action({ dispatch, getState }): RouteReturn {
			const username = getState().user.authority?.name;
			dispatch(
				showMainBanner(NotificationErrorType.SUCCESS, {
					title: `User "${username}" logged out.`,
				}),
			);
			Authorization.logout();
			return { redirect: ConfigCARSx.Pages[AppSection.LOGIN].route };
		},
	},
];

Object.entries(APP_SECTION_ROUTES).forEach(([appSection, appSectionRoutes]) => {
	if (ConfigCARSx.Pages[appSection as AppSection].active) {
		routesList = [...routesList, ...appSectionRoutes];
	}
});

export const routes = [...routesList];

// decorator-type function to evaluate the `action` of a SafeRoute and then check its cancellation token
// ... to decide its return value and whether or not to update the state
const resolveRouteAction = (
	action: (
		context: RouteContext<RouteReturn, OwRouteContext> & OwRouteContext,
		params: Params,
	) => RouteResult<RouteReturn>,
	nav?: (searchParams: URLSearchParams, params: Params) => Partial<Navigate>,
) => {
	return async (
		context: RouteContext<RouteReturn, OwRouteContext> & OwRouteContext,
		params: Params,
	) => {
		const actionReturn = await action.apply(this, [context, params]);

		// TODO: if routes ever do more than just action() -> nav(), consider using a generator function here to repeatedly check token before subsequent operations
		if (context.cancelToken.isCancellationRequested) {
			return false;
		}

		if (nav) {
			// TODO: instead of having nav() return some data, just have it dispatch whatever
			/// ... actions should happen when the route resolves, e.g. set page, set search params
			const navigation = nav(context.searchParams, params);

			context.dispatch({
				type: RoutingType.SET_PAGE,
				page: navigation.page,
				group: navigation.group,
				view: navigation.view,
				id: navigation.id,
				action: navigation.action,
			});
		}

		return actionReturn;
	};
};

// recurse through all routes and apply this decorator
const decorateRoutes = (routes: Array<SafeRoute<RouteReturn, OwRouteContext, Params>>) => {
	let stack = [...routes];

	while (stack.length) {
		const route = stack.pop();

		if (route?.action !== undefined) {
			route.action = resolveRouteAction(route?.action, route.nav);
		}

		if (route?.children?.length) {
			stack = [...stack, ...route.children];
		}
	}
};

decorateRoutes(routes);

const router = new UniversalRouter(routes as Routes<RouteReturn, OwRouteContext>, {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-expect-error
	errorHandler: (error, context: RouteContext & OwRouteContext): RouteReturn => {
		console.error('router error:', error, context);

		if (error.status === 404) {
			context.dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title: `404: "${context.pathname}" not found`,
				}),
			);
			const { pathname } = new URL(window.location.href);
			// If the current location in their url is invalid then redirect to something valid
			if (pathname === context.pathname) {
				return { redirect: '' };
			}
		}

		return false;
	},
});

export const hashRoutes: Array<SafeRoute<RouteReturn, OwRouteContext, Params>> = [
	{
		path: '',
		action({ dispatch }): RouteReturn {
			dispatch({ type: RoutingType.SET_MODAL, modalView: null });
			return true;
		},
	},
	{
		path: ModalSection.GROUP,
		children: [
			{
				path: '/new',
				action({ dispatch }): RouteReturn {
					dispatch({ type: RoutingType.SET_MODAL, modalView: ModalSection.GROUP });
					return true;
				},
			},
			{
				path: '/:id',
				action({ dispatch }, { id }): RouteReturn {
					const modalId = parseInt(id, 10);
					dispatch({ type: RoutingType.SET_MODAL, modalView: ModalSection.GROUP, modalId });
					return true;
				},
			},
		],
	},
	{
		path: `${ModalSection.GROUP_MESSAGE}/:id`,
		action({ dispatch }): RouteReturn {
			dispatch({ type: RoutingType.SET_MODAL, modalView: ModalSection.GROUP_MESSAGE });
			return true;
		},
	},
	{
		path: ModalSection.IMAGE,
		children: [
			{
				path: '/new',
				action({ dispatch }): RouteReturn {
					dispatch({ type: RoutingType.SET_MODAL, modalView: ModalSection.IMAGE });
					return true;
				},
			},
			{
				path: '/:id',
				action({ dispatch }, { id }): RouteReturn {
					const modalId = parseInt(id, 10);
					dispatch({ type: RoutingType.SET_MODAL, modalView: ModalSection.IMAGE, modalId });
					return true;
				},
			},
		],
	},
	{
		path: `${ModalSection.PASSWORD_RESET}/:token`,
		action({ dispatch }, { token }): RouteReturn {
			dispatch({ type: RoutingType.SET_MODAL, modalView: ModalSection.PASSWORD_RESET });

			// Show modal first, assuming things will work out, then close modal if token is invalid
			void dispatch(checkPasswordResetToken(token));

			return true;
		},
	},
];

const hashRouter = new UniversalRouter(hashRoutes, {
	errorHandler: (error: RouteError): RouteReturn => {
		if (DebuggingConfig.showConsoleLogs) {
			console.error('router error:', error);
		}
		return { redirect: '' };
	},
});

let previousToken: CancelToken | undefined;
let currentToken: CancelToken | undefined;

//	FIXME: current implementation passes 'undefined' to to navigate occasionally - what's the expected behavior?
export const navigate =
	(href = ''): ThunkActionRoot<Promise<void>> =>
	async (dispatch, getState): Promise<void> => {
		dispatch(setLoadingRoute(true));
		if (getState().user?.authority === undefined && Authorization.JWT !== null) {
			const userSessionValid = await dispatch(getUserPermissions());
			if (userSessionValid === false) {
				dispatch(clearItems()); // clear loaders
				return;
			}
		}
		const origin = window.location.origin || `${window.location.protocol}//${window.location.host}`;
		const normHref = new URL(!href.startsWith(origin) ? `${origin}${href}` : href);
		const { searchParams } = normHref;

		dispatch(clearItems()); // clear loaders

		let hashRet: RouteReturn | undefined | null;
		let hashPath: string | undefined = '';
		if (normHref.hash.substring(1)) {
			do {
				hashPath =
					typeof hashRet === 'object' && hashRet !== null && 'redirect' in hashRet
						? hashRet.redirect
						: normHref.hash.substring(1);
				// eslint-disable-next-line no-await-in-loop
				hashRet = await hashRouter.resolve({
					pathname: hashPath,
					searchParams,
					dispatch,
					getState,
				});
			} while (typeof hashRet === 'object' && hashRet !== null && 'redirect' in hashRet);
		} else {
			dispatch({ type: RoutingType.SET_MODAL, modalView: null });
		}

		let ret: RouteReturn | undefined | null;
		let path: string | undefined;
		if (!href.startsWith('#')) {
			previousToken = currentToken;
			/** "Tell" reference which has already been passed into action functions that cancellation has been requested. */
			previousToken?.cancel();
			/** Create a new reference for a new request leaving the old reference inaccessible from this context */
			currentToken = new CancelToken();
			do {
				path =
					typeof ret === 'object' && ret !== null && 'redirect' in ret
						? ret.redirect
						: normHref.pathname;
				// eslint-disable-next-line no-await-in-loop
				ret = await router.resolve({
					pathname: path,
					searchParams,
					dispatch,
					getState,
					cancelToken: currentToken,
				});
			} while (typeof ret === 'object' && 'redirect' in ret);
		}

		if (ret !== false) {
			const { hash, pathname } = window.location;
			if (path && `${path}#${hashPath as string}` !== pathname + hash) {
				window.history.pushState({}, '', `${path}${hashPath ? `#${hashPath}` : ``}`);
				window.scrollTo({ top: 0 });
			} else if (hashPath !== hash.substring(1)) {
				window.history.pushState({}, '', `${pathname}${hashPath ? `#${hashPath}` : ``}`);
				window.scrollTo({ top: 0 });
			}
		}

		dispatch(setLoadingRoute(false));
	};

//	REDUCER

export const RoutingReducer = (
	state: RoutingState = ROUTING_STATE_INITIAL,
	action: RoutingAction | undefined = undefined,
): RoutingState => {
	if (action === undefined) {
		return state;
	}
	switch (action.type) {
		case RoutingType.SET_PAGE:
			return {
				...state,
				page: action.page === undefined ? state.page : action.page,
				view: action.view === undefined ? state.view : action.view,
				group: action.group === undefined ? state.group : action.group,
				id: action.id ?? null,
				action: action.action ?? null,
			};
		case RoutingType.SET_MODAL:
			return {
				...state,
				modalView: action.modalView === undefined ? state.modalView : action.modalView,
				modalId: action.modalId || null,
			};
		default:
			return state;
	}
};
