import { css } from 'lit';
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/require-await */
import HTTPMethod from 'http-method-enum';
import { CameraRoleDtoBase, DeviceDtoBase } from '../../../typings/api';
import { ConfigCCTVApi } from '../../config/ConfigCCTV';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { ThunkActionRoot } from '../redux-store';
import { CCTVActionType, setCctvLoading } from './cctv-actions';
import { APIConfig, DebuggingConfig } from '../../config/ConfigCARSx';
import { showMainBanner } from '../redux-ui';
import { NotificationErrorType } from '../../../typings/shared-types';
import ConfigCARSx from '../../config/ConfigCARSx';
import store from '../redux-store';
import { navigate } from '../redux-routing';
import { CCTVView } from '../../constants';
import { getCameras } from '../redux-cctv';

export const getDevice =
	(deviceId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: CCTVActionType.GET_EXISTING_DEVICE,
		});
		const url = new URL(
			ConfigCCTVApi.getRetrieveDeviceEndpoint(deviceId),
			APIConfig.endpointURLBase,
		);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		if (apiRequestReturn.response?.ok === true) {
			try {
				const existingDevice: DeviceDtoBase =
					(await apiRequestReturn.response?.json()) as DeviceDtoBase;

				dispatch({
					type: CCTVActionType.SET_EXISTING_DEVICE,
					existingDevice,
				});
			} catch (error) {
				apiRequestReturn.apiError = APIError.ResponseUnparseable;
				if (DebuggingConfig.showConsoleLogs) {
					console.error(
						`error parsing response from "${apiRequestReturn.request?.url as string}"`,
						error,
					);
				}
			}
		}
		return apiRequestReturn;
	};

export const saveDevice =
	(device: DeviceDtoBase, group: string | undefined): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: CCTVActionType.SAVE_DEVICE });
		dispatch(setCctvLoading(true));

		const newDevice: DeviceDtoBase = {
			...device,
		};

		const url = new URL(ConfigCCTVApi.getSaveDeviceEndpoint(), APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(newDevice),
			}),
			ConfigCCTVApi.getEndpointTimeoutMs(),
		);

		if (apiRequestReturn.response?.ok) {
			void store.dispatch(
				getCameras(store.getState().cctv.cameraTypes, store.getState().user.authority?.roles),
			);

			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{ title: 'Current camera saved successfully!' },
					5000,
				),
			);
			void store.dispatch(navigate(`${ConfigCARSx.Pages.cctv.route}/${group}/${CCTVView.table}`));
		} else {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{
						title: `Error saving camera'}`,
						messages: apiRequestReturn.apiError ? [apiRequestReturn.apiError] : undefined,
					},
					5000,
				),
			);
		}
		dispatch(setCctvLoading(false));
		return apiRequestReturn;
	};

export const deleteDevice =
	(
		id: number,
		cameraNumber: string,
		group: string | undefined,
	): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		const url = new URL(ConfigCCTVApi.getDeleteDeviceEndpoint(id), APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.DELETE,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigCCTVApi.getEndpointTimeoutMs(),
		);

		if (apiRequestReturn.response?.ok) {
			void store.dispatch(
				getCameras(store.getState().cctv.cameraTypes, store.getState().user.authority?.roles),
			);

			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{ title: `Camera ${cameraNumber} deleted successfully!` },
					5000,
				),
			);
			void store.dispatch(navigate(`${ConfigCARSx.Pages.cctv.route}/${group}/${CCTVView.table}`));
		} else {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{
						title: `Error deleting camera'}`,
						messages: apiRequestReturn.apiError ? [apiRequestReturn.apiError] : undefined,
					},
					5000,
				),
			);
		}
		return apiRequestReturn;
	};

export const getCameraRoles =
	(): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: CCTVActionType.GET_CAMERA_ROLES,
		});
		const url = new URL(ConfigCCTVApi.getCameraRolesEndpoint(), APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		if (apiRequestReturn.response?.ok === true) {
			try {
				const cameraRoles: CameraRoleDtoBase =
					(await apiRequestReturn.response?.json()) as CameraRoleDtoBase;
				dispatch({
					type: CCTVActionType.SET_CAMERA_ROLES,
					cameraRoles,
				});
			} catch (error) {
				apiRequestReturn.apiError = APIError.ResponseUnparseable;
				if (DebuggingConfig.showConsoleLogs) {
					console.error(
						`error parsing response from "${apiRequestReturn.request?.url as string}"`,
						error,
					);
				}
			}
		}
		return apiRequestReturn;
	};

export const matchCameraNumber = (cameraNumber: number): boolean => {
	const url = new URL(
		ConfigCCTVApi.getMatchCameraNumberEndpoint(cameraNumber),
		APIConfig.endpointURLBase,
	);
	const request = new XMLHttpRequest();
	request.open('GET', url, false);
	request.send(null);
	if (request.status === 200) {
		return request.responseText === 'true';
	}
	return false;
};

export const matchCameraId = (cameraId: string): boolean => {
	const url = new URL(ConfigCCTVApi.getMatchCameraIdEndpoint(cameraId), APIConfig.endpointURLBase);
	const request = new XMLHttpRequest();
	request.open('GET', url, false);
	request.send(null);
	if (request.status === 200) {
		return request.responseText === 'true';
	}
	return false;
};
